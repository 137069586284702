td span.badge {
  cursor: pointer;
}

input[type="radio"].styled {
  width: 20px;
  height: 20px;
}

input[type="radio"].OPEN {
  accent-color: #ff0000;
}
input[type="radio"].CREATED {
  accent-color: #ff0000;
}

/* input[type="radio"].RESOLVED {
  accent-color: #00ff00;
} */
input[type="radio"].Closed {
  accent-color: #00ff00;
}

input[type="radio"].PROCESSING {
  accent-color: #ffcc00;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  /* background: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  /* outline: 1px solid slategrey; */
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 387px;
  overflow-y: scroll;
}

.fixed_width {
  width: 300px; 
}

.search_filter {
  overflow: auto;
  gap: 5px;
}

.search_filter::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.search_filter::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 1rem; 
}

.search_filter::-webkit-scrollbar-thumb {
  background-color: #007bff; 
  border-radius: 1rem;
}

/* .rounded-lg {
  border-radius: 0.5rem;
} */

/* input::placeholder {
  font-size: 0.9rem;
  color: #999;
} */

td span.badge {
  cursor: pointer;
}

input[type="radio"].styled {
  width: 20px;
  height: 20px;
}

input[type="radio"].OPEN {
  accent-color: #ff0000;
}
input[type="radio"].CREATED {
  accent-color: #ff0000;
}

/* input[type="radio"].RESOLVED {
  accent-color: #00ff00;
} */
input[type="radio"].Closed {
  accent-color: #00ff00;
}

input[type="radio"].PROCESSING {
  accent-color: #ffcc00;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  /* background: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  /* outline: 1px solid slategrey; */
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 387px;
  overflow-y: scroll;
}

/* On Issue Css */

td span.badge {
  cursor: pointer;
}

/* input[type=radio] {
  border: 0px;
  width: 100%;
  height: 2em;
} */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages.chat-box {
  height: 335px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="media"] {
    width: 100%!important;
    margin-top: 10px;
  }
}

.spinnerBox{
  transform: rotate(46deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.spinnerBox p{
  transform: rotate(-47deg);
  font-size: 15px;
  font-weight: bold;
}
.spinnerBox2{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-width: 15px 16px 16px;
  border-style: solid;
  border-color: blue rgb(243, 243, 243) rgb(243, 243, 243);
  border-image: initial;
}

.mobileBoxes{
  width: 45%;
  box-shadow: grey 3px 3px 6px 1px;
  border: 1px solid grey;
  border-radius: 10px;
  background: white;
}

.mobileBoxes .counti {
  font-size: 29px;
  color: black;
  font-weight: bold;
  /* margin: 0.25rem 0rem; */
}

.ticketlist{
  width: 70%;
}
.countmain{
  width: 30%;
}

.graphmain{
  width: 100%;
}

.main{
  flex-direction: column;
}

.piechart{
  display: flex;
  height: 200px;


}

@media screen  and (max-width: 426px){
  .ticketlist{
      width: 100%;
      max-height: 400px;
      overflow: scroll;
  }

  .countmain{
      width: 100%;
  }

  .graphmain{
      width: 100%;
  }

  .main{
      flex-direction: column-reverse;
  }

  .piechart{
      display: none;
  }
  
  
}
