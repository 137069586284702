.table-container {
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 15px;
  /* width: 100px!importa nt; */
  /* max-width: 100px; */
  text-align: center;
}
/* table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  /* background: #0d009d; */
  color: #0d009d;
}

th,
td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align:left;
  color: #000;
}

/* th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background: rgb(43, 114, 245);
  color: white;
  font-size: 15px;
} */
thead{
  white-space:nowrap;
}

tfoot {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

/* table td,
table th 
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 15px;
} */

table tr:hover {
  background-color: #ddd;
}


.page_link_item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  /* margin-left: -1px; */
  line-height: 1.25;
  border: 1px solid #dddfeb;
}

